import React, { useState } from "react";
import styled from "styled-components";
import info1 from "../assets/info1.png";
import info2 from "../assets/info2.png";
import info3 from "../assets/info3.png";

function Recommend() {
  const data = [
    {
      image:
        "https://vl-prod-static.b-cdn.net/system/images/000/704/557/0a4a6181917e0d0109941b7b48867c75/x510gt/Tadiandamol_trek_bmc_adventures.jpg?1693224271",
      title: "Tadiandamol Trek, Coorg",
      subTitle: "Madikeri,Karnataka",
      cost: "\u20B9 3,999/-",
      duration: "09 Feb",
      url: "https://www.bmcadventures.com/tadiandamol-trek-coorg",
      category: "Travel", // Add the category property
    },
    {
      image:
        "https://ts-production.imgix.net/images/ec02ccd8-58d0-4d89-8eda-28e75e225e61.jpg?auto=compress,format&w=800&h=450",
      title: "Japanese Macrame Workshop",
      subTitle: "Indiranagar, Bengaluru",
      cost: "\u20B9 1699/- onwards",
      duration: "Feb 18",
      url: "https://www.townscript.com/e/zen-knots-tea-a-japanese-macrame-workshop-431032",
      category: "Hobbies", // Add the category property
    },
    {
      image:
        "https://ts-production.imgix.net/images/mobile-cover-uploaded/dda76fef-2c1c-427d-aefb-53ed42db986a.jpg?auto=compress,format&w=375&h=200",
      title: "YES, AND - PLAYBACK THEATRE",
      subTitle: "Old Madras Road, Bangalore",
      cost: "\u20B9 12000",
      duration: "March 21, 22, 23, 24 (4-day workshop)",
      url: "https://www.townscript.com/e/yes-and-core-training-in-playback-theatre-432114",
      category: "Hobbies", // Add the category property
    },
    {
      image:
        "https://res.cloudinary.com/dwzmsvp7f/image/fetch/q_75,f_auto,w_800/https%3A%2F%2Fmedia.insider.in%2Fimage%2Fupload%2Fc_crop%2Cg_custom%2Fv1704728410%2Fzgzfiniuuv3uuwtc7rv5.png",
      title: "Under 25 Summit 2024",
      subTitle: "Jayamahal Palace Hotel, Bengaluru",
      cost: "\u20B9 999/- onwards",
      duration: "March 9th & 10th",
      url: "https://insider.in/under-25-summit-2024-mar9-2024/event",
      category: "Events", // Add the category property
    },
    {
      image:
        "https://assets-in.bmscdn.com/nmcms/events/banner/desktop/media-desktop-karaoke-koramangala-evenings-0-2023-10-1-t-8-52-52.jpg",
      title: "Karaoke Koramangala Evenings",
      subTitle: "Marcopolo Cafe, Koramangala, Bengaluru",
      cost: "\u20B9 300/-",
      duration: "11 Feb - 28 Feb",
      url: "https://in.bookmyshow.com/events/karaoke-koramangala-evenings/ET00338640",
      category: "Events", // Add the category property
    },
    {
      image:
        "https://assets-in.bmscdn.com/nmcms/events/banner/desktop/media-desktop-paint-with-fluid-pouring-art-0-2023-9-23-t-13-49-55.jpg",
      title: "Paint with Fluid ( Pouring Art )",
      subTitle: "Lahe Lahe, Bangalore",
      cost: "\u20B9 1500/-",
      duration: "February 10 to February 24",
      url: "https://in.bookmyshow.com/events/paint-with-fluid-pouring-art/ET00370599",
      category: "Hobbies", // Add the category property
    },
    {
      image:
        "https://assets-in.bmscdn.com/nmcms/events/banner/desktop/media-desktop-church-street-gaming-by-the-boardgame-den-0-2023-7-25-t-10-47-15.jpg",
      title: "Church Street Gaming by Boardgame Den",
      subTitle: "WeWork, The Pavillion, Bangalore",
      cost: "\u20B9 450/-",
      duration: "February 04 to February 25",
      url: "https://in.bookmyshow.com/activities/church-street-gaming-by-the-boardgame-den/ET00365330",
      category: "Events", // Add the category property
    },
    {
      image:
        "https://vl-prod-static.b-cdn.net/system/images/000/521/325/00dfc1ad164f08ec5088add0d7c72a6e/x765gt/Bandaje_Falls_trek_bmc_05.jpg?1637822725",
      title: "Bandaje Falls Trek",
      subTitle: "Chikmagalur,Karnataka",
      cost: "\u20B9 4,099/-",
      duration: "26 Jan, 02 Feb, 09 Feb, and more",
      url: "https://www.bmcadventures.com/tours/bandaje-falls-trek-ballalrayana-durga-23093",
      category: "Travel", // Add the category property
    },
    {
      image:
        "https://vl-prod-static.b-cdn.net/system/images/000/561/676/c8cee0d27f3c2f5df7e076f634f21c12/x765gt/gokarna_beach_trek_01.jpg?1649947687",
      title: "Gokarna Beach Trek & Camping",
      subTitle: "Gokarna,Karnataka",
      cost: "\u20B9 4000/-",
      duration: "26 Jan, 02 Feb, 09 Feb, and more",
      url: "https://www.bmcadventures.com/tours/gokarna-beach-trek-21083",
      category: "Travel", // Add the category property
    },
    {
      image:
        "https://res.cloudinary.com/dwzmsvp7f/image/fetch/q_75,f_auto,w_800/https%3A%2F%2Fmedia.insider.in%2Fimage%2Fupload%2Fc_crop%2Cg_custom%2Fv1704287328%2Ftlwemvqjtwm4zlta2i3l.png",
      title: "Zomaland by Zomato Live | Bengaluru",
      subTitle: "NICE Ground, Bengaluru",
      cost: "\u20B9 699/- onwards",
      duration: "March 16-17",
      url: "https://insider.in/zomaland-by-zomato-live-bengaluru-mar16-2024/event",
      category: "Events", // Add the category property
    },
    {
          image:
            "https://res.cloudinary.com/dwzmsvp7f/image/fetch/q_75,f_auto,w_800/https%3A%2F%2Fmedia.insider.in%2Fimage%2Fupload%2Fc_crop%2Cg_custom%2Fv1706787039%2Fty85edyihcgxvqezgxtj.jpg",
          title: "Entrepreneurs Meetup by We Founders Collab",
          subTitle: "BHIVE Workspace MG Road, Bengaluru",
          cost: "\u20B9 475/-",
          duration: "April 27",
          url: "https://insider.in/entrepreneurs-meetup-by-we-founders-collab-apr27-2024/event",
          category: "Events", // Add the category property
    },
  ];

  const packages = ["Top Rated", "Events", "Hobbies", "Travel"];

  const [active, setActive] = useState(1);
  const [filteredData, setFilteredData] = useState(data);

  const handlePackageClick = (index) => {
    setActive(index + 1);

    // Filter data based on the selected category
    if (index === 0) {
      // "Top Rated" - Show all data
      setFilteredData(data);
    } else {
      // Show data based on the selected category
      const category = packages[index];
      setFilteredData(data.filter((item) => item.category === category));
    }
  };
  return (
    <Section id="recommend">
      <div className="title">
        <h2> DISCOVER. EXPERIENCE. GROW.</h2>
      </div>
      <div className="packages">
        <ul>
          {packages.map((pkg, index) => (
            <li
              key={index}
              className={active === index + 1 ? "active" : ""}
              onClick={() => handlePackageClick(index)}
            >
              {pkg}
            </li>
          ))}
        </ul>
      </div>
      <div className="destinations">
        {filteredData.map((destination, index) => (
          <a
            href={destination.url}
            target="_blank"
            rel="noopener noreferrer"
            key={index}
            className="destination-link"
          >
            <div className="destination">
              <img
                style={{ objectFit: "contain" }}
                src={destination.image}
                alt=""
              />
              <h3>{destination.title}</h3>
              <p>{destination.subTitle}</p>
              <div className="info">
                <div className="services">
                  <img src={info1} alt="" />
                  <img src={info2} alt="" />
                  <img src={info3} alt="" />
                </div>
                <h4>{destination.cost}</h4>
              </div>
              <div className="distance">
                <span>{destination.duration}</span>
              </div>
            </div>
          </a>
        ))}
      </div>
    </Section>
  );
}

const Section = styled.section`
  padding: 2rem 0;
  .title {
    text-align: center;
    color: #ffffff;
    font-size: 30px;
  }
  .packages {
    display: flex;
    justify-content: center;
    margin: 2rem 0;
    color: #ffffff;
    overflow-x: auto; /* Enable horizontal scrollbar if needed */
    ul {
      display: flex;
      list-style-type: none;
      width: max-content;
      white-space: nowrap; /* Prevent line breaks for the list items */
      li {
        padding: 1rem 2rem;
        border-bottom: 0.1rem solid white;
      }
      .active {
        border-bottom: 0.5rem solid #8338ec;
      }
    }
  }

  .destination-link {
    text-decoration: none; /* Remove underline */
    color: #000000; /* Set the color for unvisited links */
  }

  .destination-link:visited {
    color: #000000; /* Set the color for visited links */
  }

  .destinations {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
    padding: 0 3rem;
    .destination {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      background-color: #ffffff;
      border-radius: 1rem;
      transition: 0.3s ease-in-out;
      &:hover {
        transform: translateX(0.4rem) translateY(-1rem);
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }
      img {
        width: 100%;
        height: 240px;
        object-fit: cover;
        border-radius: 1rem;
      }
      .info {
        display: flex;
        align-items: center;

        .services {
          display: flex;
          gap: 0.3rem;

          img {
            border-radius: 1rem;
            background-color: #4d2ddb84;
            width: 2rem;
            height: 2rem; /* Set the desired fixed height for the images */
            padding: 0.3rem 0.4rem;
            flex-shrink: 0; /* Prevent the image from shrinking */
          }
        }

        justify-content: space-between;
      }

      .distance {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  @media screen and (max-width: 980px) {
    .packages {
      ul {
        li {
          padding: 0 0.5rem;
          font-size: 2vh;
          padding-bottom: 1rem;
        }
        .active {
          border-bottom-width: 0.3rem;
        }
      }
    }
    .destinations {
      grid-template-columns: 1fr;
      padding: 0;
      .destination {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 1rem;
        }
      }
    }
  }
`;

export default Recommend;
