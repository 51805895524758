import React from "react";
import styled from "styled-components";
import harini from "../assets/harini.png";
import siddarth from "../assets/siddarth.png";
import charan from "../assets/charan.png";
function Testimonials() {
return(
<Section id="testimonials">
      <div className="title">
        <br/>
      <hr style={{ height: '3px', width: '1400px', color: '#FFFFFF', backgroundColor: '#FFFFFF', margin: '25px auto'}}/>
        <h2>JOIN THE XPERIOS COMMUNITY</h2>
        <hr style={{ height: '3px', width: '1400px', color: '#FFFFFF', backgroundColor: '#FFFFFF', margin: '25px auto'}}/>
      </div>
      <div className="testimonials">
        <div className="testimonial">
          <p>
          Joined the Xperios group and instantly found the pulse of my city! From local events to hidden gems, it's my go-to for new experiences
          </p>
          <div className="info">
            <img src={charan} alt="" />
            <div className="details">
              <h4>Sai Charan</h4>
              <span>Full Stack Developer</span>
            </div>
          </div>
        </div>
        <div className="testimonial">
          <p>
          Discovering the heartbeat of my city was never easier! The Xperios community keeps me updated on nearby happenings and exciting new activities.
          </p>
          <div className="info">
            <img src={harini} alt="" />
            <div className="details">
              <h4>Harini Anand</h4>
              <span>Co-Founder of Dementia Care</span>
            </div>
          </div>
        </div>
        <div className="testimonial">
          <p>
          Love being part of the Xperios group! It's like having a personal guide to the city's best events and experiences right at my fingertips.
          </p>
          <div className="info">
            <img src={siddarth} alt="" />
            <div className="details">
              <h4>Siddarth D Pai</h4>
              <span>Python, C++, Java Developer</span>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}
const Section = styled.section`
  margin: 5rem 0;
  .title {
    text-align: center;
    margin-bottom: 2rem;
    color:#FFFFFF
  }
  .testimonials {
    display: flex;
    justify-content: center;
    margin: 0 2rem;
    gap: 2rem;
    .testimonial {
      background-color: #FFFFFF   ;
      padding: 2rem;
      border-radius: 0.5rem;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      transition: 0.3s ease-in-out;
      &:hover {
        transform: translateX(0.4rem) translateY(-1rem);
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }
      .info {
        display: flex;
        justify-content: center;
        gap: 1rem;
        align-items: center;
        margin-top: 1rem;
        img {
          border-radius: 3rem;
          height: 3rem;
        }
        .details {
          span {
            font-size: 0.9rem;
          }
        }
      }
    }
  }
  @media screen  and (max-width: 980px) {
    .testimonials {
      flex-direction: column;
      margin: 0;
      .testimonial {
        justify-content: center;
        .info {
          flex-direction: column;
          justify-content: center;
        }
      }
    }
  }
`;
export default Testimonials

