import React from "react";
import styled from "styled-components";
import deepika from "../assets/deepika.jpeg";
import lakshmi from "../assets/lakshmi.jpeg";
import linkedinicon from "../assets/linkedin.png";
import emailicon from "../assets/email.png";

function Services() {
  const redirectToLinkedIn = (url) => {
    // Use window.location.href to redirect to the LinkedIn profile
    window.location.href = url;
  };
  
  const data = [
    {
      iconurl: deepika,
      title: "Deepika Indran",
      subTitle: "Co-Founder",
      isSquare: false,
      linkedin: "https://www.linkedin.com/in/deepika-indran-ab0993227/",
      email: "mailto:deepika@xperios.in"
    },
    {
      title: "Celebrate Youth",
      subTitle:
        "Unleash the potential of your youth with a curated blend of hobbies, networking, and travel experiences, all in one engaging platform!",
      isSquare: true,
    },
    {
      title: "Enhance Everyday Living",
      subTitle:
        "Whether you're seeking the latest events in your city, looking for a new hobby, or planning your next getaway, Xperios has you covered!",
      isSquare: true,
    },
    {
      iconurl: lakshmi,
      title: "Lakshmi Narayanan",
      subTitle: "Co-Founder",
      isSquare: false,
      linkedin: "https://www.linkedin.com/in/lakshmi-n-i/",
      email: "mailto:lakshmi@xperios.in"
    },
  ];

  const StyledServiceEvent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
    background-color: #ffffff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: 0.3s ease-in-out;

    &:hover {
      transform: translateX(0.4rem) translateY(-1rem);
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .icon {
      width: 100px;
      height: 100px;
      overflow: hidden;
      border-radius: 50%;

      &.square {
        border-radius: 0;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    /* Adjust the size of Service 2 and Service 3 icons */
    &:nth-child(2) .icon img,
    &:nth-child(3) .icon img {
      width: 45%;
      height: 50%;
    }
  `;

  const StyledServicePerson = styled.div`
    gap: 1rem;
    padding: 2rem;
    background-color: #ffffff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: 0.3s ease-in-out;

    &:hover {
      transform: translateX(0.4rem) translateY(-1rem);
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
    .col {
      display: flex;
      flex-direction: row;
    }
    .col > div {
      flex: 1;
    }

    .col2 {
      display: flex;
      flex-direction: row;
    }

    .icon {
      width: 50px;
      height: 100px;
      overflow: hidden;
      border-radius: 50%;
      background-color: '#ff0000',
      justify-content: center; /* You can add any additional styles as needed */

      &.square {
        border-radius: 0;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    /* Adjust the size of Service 2 and Service 3 icons */
    &:nth-child(2) .icon img,
    &:nth-child(3) .icon img {
      width: 45%;
      height: 50%;
    }
  `;

  const PersonCard = (props) => {
    const { title, subTitle, img, linkedin, email } = props;
    
    return (
      <StyledServicePerson>
        <div>
          <div className="col">
            <img
              src={img}
              alt=""
              style={{
                height: 83,
                width: 83,
                marginRight: 16,
                borderRadius: 99,
                cursor: "pointer",
              }}
            />
            <div style={{ marginTop: 10 }}>
              <h3 style={{ fontSize: 20, marginBottom: 5 }}>{title}</h3>
              <p style={{ fontSize: 18 }}>{subTitle}</p>
            </div>
          </div>
          <div className="col" style={{ marginTop: 15 }}>
            <div style={{ display: 'flex', gap: '15px', justifyContent: 'center', alignItems: 'center' }}>
              <img
                src={linkedinicon}
                style={{ height: 35 }}
                onClick={() => redirectToLinkedIn(linkedin)}
              />
              <img
                src={emailicon}
                style={{ height: 34, width: 34 }}
                onClick={() => window.location.href = email}
              />
            </div>
          </div>
        </div>
      </StyledServicePerson>
    );
  };

  const EventCard = (props) => {
    const { title, subTitle, iconurl } = props;

    return (
      <StyledServiceEvent>
        <div style={{ marginTop: 10 }}>
          <div>
            <h3>{title}</h3>
            <p style={{ marginTop: 7 }}>{subTitle}</p>
          </div>
        </div>
      </StyledServiceEvent>
    );
  };

  return (
    <div>
      <Section id="Service">
        <PersonCard
          title={data[0].title}
          subTitle={data[0].subTitle}
          isSquare={data[0].isSquare}
          img={data[0].iconurl}
          linkedin={data[0].linkedin}
          email={data[0].email}
        />
        <EventCard
          title={data[1].title}
          subTitle={data[1].subTitle}
          isSquare={data[1].isSquare}
          img={data[1].iconurl}
        />
        <EventCard
          title={data[2].title}
          subTitle={data[2].subTitle}
          isSquare={data[2].isSquare}
          img={data[2].iconurl}
        />
        <PersonCard
          title={data[3].title}
          subTitle={data[3].subTitle}
          isSquare={data[3].isSquare}
          img={data[3].iconurl}
          linkedin={data[3].linkedin}
          email={data[3].email}
        />
      </Section>
    </div>
  );
}

const Section = styled.section`
  padding: 5rem 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  .service {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
    background-color: #ffffff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: 0.3s ease-in-out;
    &:hover {
      transform: translateX(0.4rem) translateY(-1rem);
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
    .icon {
      width: 100px; /* Set a fixed width and height for the circular frame */
      height: 100px;
      overflow: hidden; /* Ensure the image doesn't overflow the circular frame */
      border-radius: 50%; /* Make the frame circular */
      &.square {
        border-radius: 0; /* Make the frame square if the square class is present */
      }
      img {
        width: 100%; /* Make the image fill the circular frame */
        height: auto; /* Maintain the aspect ratio of the image */
      }
    }
    /* Adjust the size of Service 2 and Service 3 icons */
    &:nth-child(2) .icon img,
    &:nth-child(3) .icon img {
      width: 45%; /* Set the width to 100% */
      height: 50%; /* Maintain the aspect ratio */
    }
    .foundercontact{
      display: flex; /* Display images in a row */
      gap : 20px; /* Adjust the gap between images */
    }
  }
  @media screen and (min-width: 280px) and (max-width: 720px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export default Services;
