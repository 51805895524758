import React from "react";
import ScrollToTop from "./components/ScrollToTop";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Services from "./components/Services";
import Recommend from "./components/Recommend";
import Testimonials from "./components/Testimonials";
import Footer from "./components/Footer";
import styled from "styled-components";

const AppWrapper = styled.div`
  wdith: 1000vw /* Set your desired maximum width */
  margin: 0 auto; /* Center the content */
  overflow-x: hidden; /* Hide horizontal overflow */
`;

function App() {
  return (
    <AppWrapper>
      <ScrollToTop />
      <Navbar />
      <Hero />
      <Services />
      <Recommend />
      <Testimonials />
      <Footer />
    </AppWrapper>
  );
}

export default App;
