import React from 'react'

function ScrollToTop() {
  return (
    <div>
      
    </div>
  )
}

export default ScrollToTop

